// Generateed from scripts/createEnums.ts

// Check https://camelotgroup.xplan.iress.co.nz/ufield/edit/entity/title
export enum EntityTitleChoices {
  Dr = 'Dr',
  Judge = 'Judge',
  Justice = 'Justice',
  Master = 'Master',
  Miss = 'Miss',
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Prof = 'Prof',
  Rev = 'Rev',
  Coroner = 'Coroner',
  Lady = 'Lady',
  Sir = 'Sir'
}

export enum EntityMaritalStatusChoices {
  CivilUnion = 'Civil Union',
  DeFacto = 'Defacto',
  Divorced = '4',
  Married = '2',
  Separated = '3',
  Single = '1',
  Widowed = 'Widowed',
  Unknown = 'Unknown'
}

export enum EntitySexChoices {
  Male = '1',
  Female = '0',
  X = '2'
}

export enum OccupationCategory {
  WhiteCollar = '1A - White Collar Professional - Uni Qualified & Prof Assoc.',
  WhiteCollarExec = '1B - White Collar Professional - High Earning Execs.',
  MedicalProfessionals = '1M - Medical Professionals',
  Managers = '2A - Managers & Business Execs.',
  Admins = '2B - Clerical & Administration',
  WhiteCollarLight = '2C - Light Manual - White Collar',
  BlueCollarLight = '3A - Light Manual - Skilled Blue Collar',
  BlueCollarModerate = '3B - Moderate Manual - Skilled Blue Collar',
  BlueCollarHeavy = '4 - Heavy Manual/Moderate Risk - Blue Collar',
  BlueCollarHeavyHighRisk = '5 - Heavy Manual/High Risk - Blue Collar'
}

// Map each JobCategory to a UUID - https://camelotgroup.xplan.iress.co.nz/resourceful/#method:PATCH%20/resourceful/riskresearcher/au/scenario/:scenario_id/life/:life_id
export const OccupationCategoryUUIDMap: {
  [key in OccupationCategory]: string
} = {
  [OccupationCategory.WhiteCollar]: '00000000-0000-0000-0000-000000008860',
  [OccupationCategory.WhiteCollarExec]: '00000000-0000-0000-0000-000000008861',
  [OccupationCategory.MedicalProfessionals]:
    '00000000-0000-0000-0000-000000008862',
  [OccupationCategory.Managers]: '00000000-0000-0000-0000-000000008863',
  [OccupationCategory.Admins]: '00000000-0000-0000-0000-000000008864',
  [OccupationCategory.WhiteCollarLight]: '00000000-0000-0000-0000-000000008865',
  [OccupationCategory.BlueCollarLight]: '00000000-0000-0000-0000-000000008866',
  [OccupationCategory.BlueCollarModerate]:
    '00000000-0000-0000-0000-000000008867',
  [OccupationCategory.BlueCollarHeavy]: '00000000-0000-0000-0000-000000008868',
  [OccupationCategory.BlueCollarHeavyHighRisk]:
    '00000000-0000-0000-0000-000000008869'
}

export enum EntityHousingStatusChoices {
  Boarding = 'Boarding',
  Renting = 'Renting',
  OwnHome = 'Own Home',
  OwnHomeMortgage = 'Own Home Mortgage',
  WithParents = 'With Parents',
  MobileHome = 'Caravan',
  SuppliedByEmployer = 'Supplied by Employer',
  RightToOccupy = 'Right to Occupy',
  Other = 'Other'
}

export enum EntityUserAccessLevelChoices {
  Adviser = '1',
  AdviserXTOOLSAccess = '10',
  AdviserSupport = '3',
  ParaplannerAdviserSupport = '2',
  CommPayAdviserSupport = '11',
  Management = '4',
  ExternalUserAuditor = '5',
  SystemAdministrator = '7',
  DormantUser = '8',
  ReadOnly = '9',
  SystemAdministratorDoNotUse = '6'
}

export enum EntityResidentStatusChoices {
  EmploymentPass = 'Employment Pass',
  Foreigner = 'Foreigner',
  NZCitizen = 'NZ Citizen',
  PermanentResident = 'Permanent Resident',
  Resident = 'Resident',
  Other = 'Other'
}

export enum EntityEntityTypeChoices {
  Individual = '1',
  Company = '2',
  Trust = '3',
  Group = '4',
  Superfund = '5',
  Partnership = '6',
  ReportCategory = '10',
  ReportTemplate = '11',
  ClientGroup = '12',
  SuperPlan = '13',
  Liability = '14',
  Asset = '15',
  Annuity = '16',
  Insurance = '20',
  MarginLending = '21',
  WizardScenario = '22',
  PartnerWizardScenario = '23',
  Subfund = '24',
  GroupPlanCategory = '26'
}

export enum AddressTypeChoices {
  Postal = '0',
  Residential = '1',
  Business = '2',
  EstateHandler = 'Estate Handler',
  Registered = 'registered',
  PostSettlement = 'post_settlement',
  Other = 'Other',
  PreviousAddress = 'Previous Address',
  GoneNoAddress = 'Gone No Address'
}

export enum ContactTypeChoices {
  HomePhone = 'p1',
  WorkPhone = 'p2',
  MobilePhone = 'p3',
  Pager = 'p4',
  OtherPhone = 'p5',
  HomeFax = 'f1',
  WorkFax = 'f2',
  OtherFax = 'f3',
  HomeEmail = 'm1',
  WorkEmail = 'm2',
  OtherEmail = 'm3',
  SMSEmail = 'SMS Email',
  SMSNumber = 'ms',
  MSExchangeEmail = 'mx',
  GoogleMailGmail = 'mg',
  Website = 'w1',
  Skype = 'sk',
  PreferredEmail = 'preferred_email',
  WeChat = 'ec',
  NoEmailAddressHeld = 'No Email Address Held'
}

export const EmailContactTypes = [
  ContactTypeChoices.HomeEmail,
  ContactTypeChoices.GoogleMailGmail,
  ContactTypeChoices.MSExchangeEmail,
  ContactTypeChoices.OtherEmail,
  ContactTypeChoices.PreferredEmail,
  ContactTypeChoices.SMSEmail,
  ContactTypeChoices.WorkEmail,
  ContactTypeChoices.SMSNumber
]

export enum AssetTypeChoices {
  AccountsReceivable = '702',
  Annuity = '401',
  Antiques = '501',
  Artwork = '502',
  Bonds = '201',
  CashOnHand = '101',
  CurrentSavings = '102',
  FixedDeposits = '103',
  Franchises = '707',
  Goodwill = '706',
  HolidayHome = '303',
  HouseholdContents = '503',
  Inventory = '703',
  Investment = '708',
  InvestmentProperty = '302',
  Investments = '709',
  Jewellery = '504',
  KiwiSaver = '404',
  Livestock = '712',
  ManagedInvestments = '205',
  MotorVehicle = '505',
  Other = '405',
  OtherInvestments = '299',
  OtherLifeStyle = '506',
  OtherLifestyleProperty = '304',
  PatentsAndCopyrights = '705',
  Pension = '402',
  PlantAndMachinery = '701',
  PrepaidExpenses = '704',
  PrimaryResidence = '301',
  SecondaryResidentialProperty = '300',
  Shares = '202',
  Superannuation = '403',
  UnitTrusts = '203',
  WrapAccount = '204'
}

export enum AssetStatusChoices {
  Existing = 'Existing',
  Cancelled = 'Cancelled',
  Transferred = 'Transferred',
  Sold = 'Sold'
}

export enum AssetTypeGroupChoices {
  Investments = '2',
  Retirement = '4',
  BusinessAssets = '7',
  LifeStyle = '5',
  LiquidAssets = '1',
  RealEstate = '3',
  ManagedInvestments = '8'
}

export enum AssetKiwisaverObjectiveChoices {
  FirstHome = '1',
  Retirement = '2'
}

export enum AssetRealestateTypeChoices {
  InvestmentAsset = '1',
  LifestyleAsset = '2'
}

export enum AssetOwnerChoices {
  Client = 'Client',
  Partner = 'Partner',
  Joint = 'Joint',
  Complex = 'Complex'
}

export enum AssetRiskTypeChoices {
  Defensive = '106',
  Conservative = '107',
  Balanced = '108',
  Growth = '109',
  Aggressive = '110',
  NotSure = '411'
}

export enum HazardousTypeChoices {
  GameHunting = 'Game Hunting',
  PowerBoating = 'Power Boating',
  HorseRiding = 'Horse Riding',
  RockClimbing = 'Mountaineering/Rock Climbing',
  SkyDiving = 'Parachuting/Skydiving',
  MotorRacing = 'Motor Racing',
  Diving = 'Underwater Diving',
  Flying = 'Aviation',
  Biking = 'Mountain Biking',
  Rugby = 'Rugby / League',
  Other = 'Other'
}

export enum HazardousParticipationChoices {
  Current = 'Currently participates',
  LastThreeYears = 'Participated within the last 3 years',
  Intends = 'Intends Participating',
  MoreThanThreeYears = 'Participated more than 3 years ago'
}

export enum LiabilityTypeChoices {
  CreditCard = '101',
  OtherCurrentLoan = '102',
  OutstandingPayment = '103',
  Overdraft = '104',
  PrimaryResidenceMortgage = '201',
  PrimaryResidenceIslamicMortgage = '210',
  InvestmentPropertyMortgage = '202',
  BuyToLetMortgage = '211',
  AdditionalPropertyMortgage = '212',
  InvestmentPropertyIslamicMortgage = '213',
  CarLoan = '203',
  StudentLoan = '204',
  PersonalLoan = '205',
  OtherLongTermLoan = '206',
  JointAndSeveral = '301',
  Limited = '302'
}

export enum LiabilityTypeGroupChoices {
  Current = '1',
  LongTerm = '2',
  Contingent = '3'
}

export enum LiabilityOwnerChoices {
  Client = 'Client',
  Partner = 'Partner',
  Joint = 'Joint',
  Complex = 'Complex'
}

export enum LiabilityStatusChoices {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Current = 'Current'
}

export enum CashflowTypeChoices {
  AnnuityIncome = 'i107',
  Bonuses = 'i102',
  BusinessIncome = 'i113',
  Car = 'e905',
  CarLoan = 'e203',
  CarMaintenance = 'e703',
  CDsVideosMovies = 'ec03',
  ChildSupport = 'Child Support',
  Clothing = 'eb01',
  Commissions = 'i103',
  Contributions = 'e4',
  Cosmetics = 'eb04',
  CreditCards = 'e301',
  CustomExpenditure = 'ed99',
  Dental = 'ea02',
  DiningOut = 'e802',
  Disability = 'e902',
  Doctor = 'ea01',
  Drawings = 'Drawings',
  DryCleaning = 'eb02',
  Electricity = 'e603',
  Endowment = 'e906',
  Gas = 'e601',
  Groceries = 'e801',
  Hairdressing = 'eb03',
  Health = 'e904',
  Holiday = 'ec02',
  Home = 'e903',
  Hospital = 'ea05',
  HousingMaintenance = 'e501',
  IncomeTax = 'e101',
  InvestmentDividends = 'i105',
  InvestmentInterest = 'i104',
  InvestmentOtherIncome = 'i111',
  InvestmentRental = 'i106',
  Life = 'e901',
  LifestyleIncome = 'i112',
  Memberships = 'ec01',
  Mobile = 'e605',
  MortgagePayments = 'e201',
  Optical = 'ea03',
  SecondaryWages = 'i101',
  Other = 'e699',
  OtherCarExpenses = 'e799',
  OtherEntertainment = 'ec99',
  OtherExpenditure = 'ed01',
  OtherFoodExpenses = 'e899',
  OtherHealthCare = 'ea99',
  OtherHousing = 'e599',
  OtherIncome = 'i199',
  OtherInsurance = 'e999',
  OtherLoans = 'e399',
  OtherLongTermLoans = 'e299',
  OtherPersonalCare = 'eb99',
  OtherTax = 'e199',
  PensionIncome = 'i108',
  PersonalLoans = 'e204',
  Petrol = 'e701',
  Pharmaceutical = 'ea04',
  PropertyTax = 'e102',
  Rates = 'e607',
  RentalPayments = 'e202',
  Repairs = 'e702',
  RoadTax = 'e103',
  Royalties = 'i110',
  SocialWelfare = 'Social Welfare',
  Sports = 'ec04',
  TaxDeductibleEducationLoans = 'e205',
  TaxDeductibleMortgagePayments = 'e206',
  Telephone = 'e604',
  Television = 'e606',
  Term = 'e907',
  TradeIncome = 'i109',
  Water = 'e602',
  WholeLife = 'e908',
  RentalExpenses = 'e502',
  SportsAndRec = 'ec05',
  Transport = 'e704',
  Rent = 'e504',
  BodyCorp = 'e503',
  Entertainment = 'ec11',
  Insurance = 'e910',
  ChildCare = 'ed02',
  Education = 'ed05',
  Healthcare = 'ea06',
  Pets = 'ed04',
  General = 'ed03',
  OtherExpense = 'ed01',
  WorkingForFamilies = 'i125',
  OtherGovernmentBenefit = 'i127'
}

export enum CashflowTypeGroup {
  INCOME = 'i1',
  TAXES = 'e1',
  DEBT_LONG_TERM = 'e2',
  DEBT_SHORT_TERM = 'e3',
  CONTRIBUTIONS = 'e4',
  HOUSING = 'e5',
  UTILITIES = 'e6',
  TRANSPORT = 'e7',
  FOOD = 'e8',
  INSURANCE = 'e9',
  HEALTH_CARE = 'ea',
  PERSONAL_CARE = 'eb',
  ENTERTAINMENT = 'ec',
  OTHER_EXPENDITURE = 'ed'
}

export enum CashflowFreqChoices {
  Weekly = '52',
  Fortnightly = '26',
  TwiceAMonth = '24',
  FourWeekly = '13',
  Monthly = '12',
  BiMonthly = '6',
  Quarterly = '4',
  HalfYearly = '2',
  Yearly = '1',
  Single = '0',
  CustomMonthly = '-1'
}

export enum CashflowOwnerChoices {
  Client = 'Client',
  Partner = 'Partner',
  Joint = 'Joint'
}

// https://camelotgroup.xplan.iress.co.nz/resourceful/ufield/entity_cashflow/type
export enum IncomeSource {
  SalaryAndWages = 'i101',
  Bonuses = 'i102',
  Commissions = 'i103',
  InvestmentInterest = 'i104',
  InvestmentDividends = 'i105',
  InvestmentRental = 'i106',
  AnnuityIncome = 'i107',
  PensionIncome = 'i108',
  BusinessIncome = 'i113',
  TradeIncome = 'i109',
  Royalties = 'i110',
  InvestmentOtherIncome = 'i111',
  LifestyleIncome = 'i112',
  WorkingForFamiliesTaxCredit = 'i125',
  OtherGovernmentBenefit = 'i127',
  OtherIncome = 'i199'
}

export enum DependentDepSexChoices {
  Male = '1',
  Female = '0',
  Other = 'X'
}

export enum DocNotesPermissionChoices {
  Private = '0',
  SharedWithSpecificGroups = '3',
  SharedWithMyGroups = '1',
  SharedWithAll = '2',
  SharedWithClientsGroups = '4'
}

export enum EmploymentEmpStatusChoices {
  FullTime = 'Full-time',
  PartTime = 'Part-time',
  Casual = 'Casual',
  Unemployed = 'Unemployed',
  SelfEmployed = 'Self Employed',
  Retired = 'Retired',
  HomeMaker = 'Homemaker',
  Student = 'Student',
  FullTimeContractor = 'Full-time Contractor',
  PartTimeContractor = 'Part-time Contractor',
  Other = 'Other',
  PreviousEmployment = 'Previous Employment'
}

export enum EntityGroupRelationship {
  None = '-1', // this was added by us, xplan will never return this
  Partner = '0',
  Company = '1',
  Super = '2',
  Trust = '3',
  Family = '4',
  Other = '5',
  BeneficiaryEstate = '6',
  BeneficiaryTrust = '7',
  BusinessPartner = '8',
  Dependent = '9',
  Director = '10',
  Employee = '11',
  ExecutorOfWill = '12',
  KeyPerson = '13',
  PowerOfAttorney = '14',
  PrimaryContactPerson = '15',
  Shareholder = '16',
  Trustee = '17',
  Settlor = '18',
  Employer = '19',
  AdditionalSpouse = '100',
  AdoptedChild = '101',
  AdoptedParent = '102',
  AdoptedSibling = '103',
  AuntUncle = '104',
  ChildGuardian = '105',
  ChildInLaw = '106',
  Cousing = '107',
  DependentParentInLaw = '108',
  ExSpouse = '109',
  Fiance = '110',
  Fiancee = '111',
  FosterChild = '112',
  FosterParent = '113',
  FosterSibling = '114',
  Friend = '115',
  Godchild = '116',
  Godparent = '117',
  GrandAuntUncle = '118',
  GrandNephewNiece = '119',
  GrandChild = '120',
  GrandChildInLaw = '121',
  GrandParent = '122',
  GrandParentInLaw = '123',
  GreatGrandAuntUncle = '124',
  GreatGrandNephewNiece = '125',
  GreatGrandChild = '126',
  GreatGrandParent = '127',
  HalfSibling = '128',
  LivingSpouse = '129',
  NephewNiece = '130',
  ParentInLaw = '131',
  Servant = '132',
  StepChild = '133',
  StepGrandChild = '134',
  StepGrandParent = '135',
  StepParent = '136',
  StepSibling = '137',
  Parent = '200'
}

export enum EntityClientStatus {
  Client = '0',
  Archived = '2',
  Contact = '32',
  Deceased = '64',
  GroupPlanMembers = '4',
  Prospect = '8'
}

export enum GoalCategory {
  EstatePlanning = '0',
  Mortgage = '1',
  Protection = '2',
  SavingsAndInvestment = '3',
  Retirement = '5',
  DebtManagement = '6',
  Budgeting = '7',
  Other = '9999'
}

export enum GoalOwner {
  Client = 'client',
  Partner = 'partner',
  Joint = 'joint'
}

export enum GoalStatus {
  Fulfilled = '1',
  Unfulfilled = '2',
  PartiallyFulfilled = '3',
  Cancelled = '4'
}

export enum KiwiSaverObjective {
  FirstHome = '1',
  Retirement = '2'
}

export enum AssetInvestmentRisk {
  // there's more...
  Balanced = '108'
}

export enum LiabilityRepaymentFrequency {
  Weekly = '52',
  Fortnightly = '26',
  Monthly = '12',
  Quarterly = '4',
  HalfYearly = '2',
  Yearly = '1',
  Single = '0'
}

export enum LiabilitySupplier {
  ANZ_Bank_NZ = '          1069      ',
  ASB_Bank_NZ = '          1072      ',
  BNZ_Bank = 'BNZ Bank',
  GeneralFinance_NZ = '          1070      ',
  Heartland = 'Heartland',
  NZF_Group_Ltd = '          1067      ',
  National_Bank_NZ = '          1071      ',
  Sovereign = '          1004      ',
  Westpac_NZ = '          1068      ',
  Other = 'other',
  TSB_Bank = 'TSB Bank',
  Resimac_Financial_Services_NZ = 'Resimac Financial Services NZ',
  SBS_Bank_NZ = 'SBS Bank NZ',
  The_Cooperative_Bank_NZ = 'The Cooperative Bank NZ',
  AMP_Home_Loans_NZ = 'AMP Home Loans NZ'
}

export enum LiabilityRepaymentType {
  PrincipalAndInterest = '1',
  InterestOnly = '2',
  InterestCapitalised = '3',
  PrePaidInterest = '4'
}

export enum LiabilityRateType {
  Fixed = '100',
  Variable = '200',
  Hybrid = '300',
  Other = '400'
}

export enum LiabilityFixedRateTermType {
  Months = 'Months',
  Years = 'Years'
}

export enum LiabilityLoanTermType {
  Months = 'Months',
  Years = 'Years'
}

export enum DependentRelationshipType {
  AdditionalSpouse = 'Additional Spouse',
  AdoptedChild = 'Adopted Child',
  AdoptedParent = 'Adopted Parent',
  AdoptedSibling = 'Adopted Sibling',
  AdultDependent = 'Adult dependant',
  AuntUncle = 'Aunt/Uncle',
  Child = 'Child',
  ChildGuardian = 'Child (Guardian)',
  ChildInLaw = 'Child(In-law)',
  Cousin = 'Cousin',
  Daughter = 'Daughter',
  DependentParent = 'Dependant Parent',
  DependentParentInLaw = 'Dependent Parent-in-law',
  ExSpouse = 'Ex-Spouse',
  Fiance = 'Fiance',
  Fiancee = 'Fiancee',
  FosterChild = 'Foster Child',
  FosterParent = 'Foster Parent',
  FosterSibling = 'Foster Sibling',
  Friend = 'Friend',
  Godchild = 'Godchild',
  Godparent = 'Godparent',
  GrandAuntUncle = 'Grand Aunt/Uncle',
  GrandNephewNiece = 'Grand Nephew/Niece',
  Granddaughter = 'Grand-daughter',
  Grandson = 'Grand-son',
  Grandchild = 'GrandChild',
  GrandchildInLaw = 'GrandChild-in-Law',
  Grandparent = 'GrandParent',
  GrandparentInLaw = 'GrandParent-in-Law',
  GreatGrandAuntUncle = 'Great Grand Aunt/Uncle',
  GreatGrandNephewNiece = 'Great Grand Nephew/Niece',
  GreatGrandchild = 'Great GrandChild',
  GreatGrandparent = 'Great GrandParent',
  HalfSibling = 'Half-Sibling',
  LivingSpouse = 'Living Spouse',
  Nephew = 'Nephew',
  NephewNiece = 'Nephew/Niece',
  Niece = 'Niece',
  Other = 'Other',
  Parent = 'Parent',
  ParentInLaw = 'Parent-In-Law',
  Servant = 'Servant',
  Son = 'Son',
  StepChild = 'Step-Child',
  StepGrandchild = 'Step-GrandChild',
  StepGrandparent = 'Step-GrandParent',
  StepParent = 'Step-Parent',
  StepSibling = 'Step-Sibling',
  StepDaughter = 'Step-daughter',
  StepSon = 'Step-son'
}

export enum DesiredFutureChoices {
  Comfortable = '100',
  Choices = '200',
  Luxury = '300'
}

export enum ModuleChoices {
  GettingStarted = 'Getting Started',
  HowCanWeHelpYou = 'How Can We Help You',
  Properties = 'Properties',
  FinancialProtection = 'Financial Protection',
  FinancialProtectionV2 = 'Financial Protection V2',
  KiwiSaver = 'KiwiSaver',
  ProgressCheckIn = 'Progress Check In',
  GoalModelling = 'Goal Modelling'
}

export enum ReferralSubtypes {
  AssetProtection = 'Asset Protection',
  Business = 'Business',
  InvestmentProperty = 'Investment Property',
  FinancialPlanning = 'Financial Planning',
  KiwiSaver = 'KiwiSaver',
  Accounting = 'Accounting',
  External = 'External',
  FG = 'F & G',
  GroupAndEmployeeBenefits = 'Group and Employee Benefits',
  Insurance = 'Insurance',
  Mortgage = 'Mortgage',
  Investment = 'Investment',
  Planning = 'Planning',
  KeepingOnTrack = 'Keeping On Track'
}

export enum HealthType {
  SingleAdult = 'SingleAdult',
  Couple = 'Couple',
  SingleParentFamily = 'SingleParentFamily',
  Family = 'Family'
}

//  Excess Amount
export enum ExcessAmountChoices {
  Nil = 'Nil',
  OneHundred = '_100',
  TwoHundredFiftyThreeHundred = '_250and300',
  FiveHundredSixHundred = '_500and600',
  OneThousandTwelveHundred = '_1000and1200',
  TwoThousand = '_2000',
  FourThousand = '_4000',
  FiveThousand = '_5000',
  SixThousand = '_6000',
  EightThousand = '_8000',
  TenThousand = '_10000'
}

export enum HealthCoverTypes {
  NonPharmac = 'Non-Pharmac',
  OpticalDental = 'Optical/Dental',
  GP = 'GP',
  Specialists = 'Specialists'
}

export enum InsuranceInScopeTypes {
  Health = '1',
  IncomeProtection = '2',
  Trauma = '3',
  TPD = '4',
  Life = '5'
}

export enum IncomeProtectionWaitPeriod {
  TwoWeeks = '_14days',
  ThreeWeeks = '_21days',
  FourWeeks = '_30days',
  EightWeeks = '_60days',
  ThirteenWeeks = '_90days',
  TwentySixWeeks = '_180days',
  FiftyTwoWeeks = '_1year',
  OneHundredFourWeeks = '_2years'
}

export enum IncomeProtectionBenefitPeriod {
  OneYear = '_1year',
  TwoYears = '_2years',
  FiveYears = '_5years',
  TenYears = '_10years',
  ToAge55 = 'toAge55',
  ToAge60 = 'toAge60',
  ToAge65 = 'toAge65'
}

export enum IncomeProtectionType {
  Indemnity = 'Indemnity',
  AgreedValue = 'AgreedValue'
}

// Once iress fix their endpoints you'll need to change to the NZ endpoint and add _5_Yr_Renewal and _10_Yr_Renewal to the enum here
export enum IncomeProtectionPremiumStyle {
  Stepped = 'Stepped',
  Level = 'Level'
  // FiveYearRenewal = '_5_Yr_Renewal',
  // TenYearRenewal = '_10_Yr_Renewal'
}
