import React, { useEffect } from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HTMLChakraProps,
  InputProps,
  Select
} from '@packages/ui-components'
import { ErrorMessage, useField } from 'formik'

import type { BaseProps } from './base-props'
export type DropdownControlProps = BaseProps & {
  inputProps?: InputProps
  dropdownOptions?: { key: string; label: string | undefined }[]
  labelStyle?: HTMLChakraProps<'label'>
  Tooltip?: React.ElementType
  placeholder?: string
  useKey?: boolean
  dropdownOptionsSize?: 'sm' | 'md' | 'lg'
  initialValue?: string
  bg?: string
  fontSize?: string
  fontColor?: string
}

const DropdownControl = (props: DropdownControlProps) => {
  const {
    name,
    label,
    onChange,
    dropdownOptions,
    labelStyle,
    useKey,
    placeholder,
    Tooltip,
    children,
    inputProps,
    dropdownOptionsSize,
    initialValue,
    bg = 'white',
    fontSize,
    fontColor,
    ...rest
  } = props
  const [field, meta, { setValue }] = useField(name)

  if (!dropdownOptions || dropdownOptions.length === 0) {
    console.error('Whooopsie. The prop "dropdownOptions" is empty or undefined')
    return null
  }

  useEffect(() => {
    if (field && !field.value && dropdownOptions.length !== 0 && !useKey) {
      if (initialValue) {
        setValue(initialValue)
      } else {
        setValue(dropdownOptions[0].label)
      }
    }
  }, [])

  const LabelMemo = () => (
    <FormLabel htmlFor={name} {...labelStyle}>
      {label}
    </FormLabel>
  )

  return (
    <FormControl
      id={name}
      name={name}
      isInvalid={!!(meta.error && meta.touched)}
      isRequired={props.isRequired}
      onChange={onChange}
      {...rest}
      {...inputProps}
    >
      {label ? (
        Tooltip ? (
          <Tooltip>
            <LabelMemo />
          </Tooltip>
        ) : (
          <LabelMemo />
        )
      ) : null}

      <Select
        bg={bg}
        {...field}
        size={dropdownOptionsSize || 'lg'}
        placeholder={placeholder}
        style={{ fontSize: props.fontSize, color: props.fontColor }}
      >
        {dropdownOptions &&
          dropdownOptions.map((option) => {
            return (
              <option
                key={option.key}
                value={useKey ? option.key : option.label}
              >
                {option.label}
              </option>
            )
          })}
      </Select>
      {children}
      <ErrorMessage component={FormErrorMessage} name={props.name} />
    </FormControl>
  )
}
export default DropdownControl
